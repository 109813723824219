export default class TableOfContents {
  constructor(selector = 'nav.table-of-contents') {
    this.element = document.querySelector(selector);

    if(this.element) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => { this.update(); });
      });

      this.headlines.forEach((headline) => this.observer.observe(headline));

      this.update();
    }
  }

  get headlines() {
    return [...this.element.querySelectorAll('a')].map((element) => document.querySelector(element.hash));
  }

  update() {
    this.headlines.forEach((headline) => {
      let bounds = headline.getBoundingClientRect();
      let link = this.element.querySelector(`a[href="#${headline.id}"]`);
      let item = link.parentElement;

      if(bounds.top < 0) {
        item.classList.add('viewed');
      } else {
        item.classList.remove('viewed');
      }
    })
  }
}
