import Cookies from './cookies';

export default class CookieConsent {
  constructor(selector = '.cookie-consent') {
    this.element = document.querySelector(selector);

    this.acceptButton.addEventListener('click', () => this.accept());
    this.rejectButton.addEventListener('click', () => this.reject());

    if(this.selection === 'true') {
      this.acceptCookies();
    } else if(this.selection === 'false') {
      this.rejectCookies();
    } else {
      this.open();
    }
  }

  open() {
    this.element.classList.add('visible');
  }

  close() {
    this.element.classList.remove('visible');
  }

  acceptCookies() {
    for(let snippet of this.snippets) {
      let selector = snippet.dataset.target;
      let target = document.querySelector(selector);

      if(target) {
        target.appendChild(snippet.content);
      }

      snippet.remove();
    }
  }

  rejectCookies() {
    for(let snippet of this.snippets) {
      snippet.remove();
    }
  }

  accept() {
    this.selection = true;
    this.acceptCookies();
    this.close();
  }

  reject() {
    this.selection = false;
    this.rejectCookies();
    this.close();
  }

  get selection() {
    return Cookies.get('cookie-consent');
  }

  set selection(value) {
    return Cookies.set('cookie-consent', value);
  }

  get acceptButton() {
    return this.element.querySelector('.button.accept');
  }

  get rejectButton() {
    return this.element.querySelector('.button.reject');
  }

  get snippets() {
    return document.querySelectorAll('template[data-target]');
  }
}
