export default class Scroller {
  constructor(element) {
    this.element = element

    let container = this.element.querySelector('.scroll-container');
    let groups = Array.from(this.element.querySelectorAll('.scroll-group'));

    groups.forEach((group) => {
      let children = Array.from(group.children);

      while(group.scrollWidth < 2 * container.offsetWidth) {
        children.forEach((item) => {
          let clonedItem = item.cloneNode(true);
          clonedItem.setAttribute('aria-hidden', true);
          group.appendChild(clonedItem);
        });
      }
    });
  }
}
