import Cookies from './cookies';

export default class Notification {
  constructor(element) {
    this.element = element;

    this.button.addEventListener('click', () => this.close());

    if(!this.isClosed && this.isActive) {
      this.open();
    }
  }

  open() {
    this.element.classList.add('visible');
  }

  close() {
    this.hide();
    this.isClosed = true;
  }

  hide() {
    this.element.classList.remove('visible');
  }

  get button() {
    return this.element.querySelector('.close');
  }

  get hash() {
    return this.element.dataset.hash;
  }

  get ref() {
    return this.element.dataset.ref;
  }

  get isClosed() {
    return Cookies.get(`notification-${this.hash}-closed`);
  }

  get isActive() {
    return (this.ref == this.param) || this.isFallback;
  }

  get isFallback() {
    return !this.ref && !document.querySelector(`.notification[data-ref=${this.param}]`)
  }

  get param() {
    let params = new URLSearchParams(window.location.search);
    return params.get('ref');
  }

  set isClosed(value) {
    Cookies.set(`notification-${this.hash}-closed`, value);

    return value;
  }
}
