import lunr from 'lunr';

function mark(element, matches) {
  var nodeFilter = {
    acceptNode: function (node) {
      if (/^[\t\n\r ]*$/.test(node.nodeValue)) {
        return NodeFilter.FILTER_SKIP
      }
      return NodeFilter.FILTER_ACCEPT
    }
  }

  var index = 0,
      matches = matches.sort(function (a, b) { return a[0] - b[0] }).slice(),
      previousMatch = [-1, -1],
      match = matches.shift(),
      node,
      walker = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT,
        nodeFilter,
        false
      );

  while (node = walker.nextNode()) {
    if (match == undefined) break;
    if (match[0] == previousMatch[0]) continue;

    var text = node.textContent,
        nodeEndIndex = index + node.length;

    if (match[0] < nodeEndIndex) {
      var range = document.createRange(),
          tag = document.createElement('mark'),
          rangeStart = match[0] - index,
          rangeEnd = rangeStart + match[1];

      tag.dataset.rangeStart = rangeStart
      tag.dataset.rangeEnd = rangeEnd

      range.setStart(node, rangeStart)
      range.setEnd(node, rangeEnd)
      range.surroundContents(tag)

      index = match[0] + match[1]

      // the next node will now actually be the text we just wrapped, so
      // we need to skip it
      walker.nextNode()
      previousMatch = match
      match = matches.shift()
    } else {
      index = nodeEndIndex
    }
  }
}

function isPresent(value) {
  return value != null && (/\w+/).test(value);
}

export default class Search {
  constructor(selector, path) {
    this.path = path;
    this.container = document.querySelector(selector);
    this.template = document.querySelector('#search-result');

    if(this.container && this.template) {
      this.element = this.container.querySelector('input');
      this.dialog = this.container.querySelector('.results');
      this.results = this.container.querySelector('.results ul');

      this.element.addEventListener('input', (event) => this.onInput(event));
      this.element.addEventListener('keyup', (event) => this.onKeyUp(event));

      let url = new URL(window.location.href);
      let query = url.searchParams.get('q');

      if(isPresent(query)) {
        this.element.value = query;
        this.onInput();
      }
    }
  }

  onKeyUp(event) {
    if(event.key === 'Escape' || event.key == 'Esc') {
      this.element.value = null;
      this.closeResults();
    } else if (event.key === 'Enter') {
      this.currentSelection?.click();
    }
  }

  async onInput() {
    let query = this.element.value;

    if(query.length > 0) {
      this.openResults();
    } else {
      this.closeResults();
    }

    this.showMessage('Searching...');

    if(this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => this.search(query), 250);
  }

  openResults() {
    this.dialog.classList.add('visible');
  }

  closeResults() {
    if(this.timeout) {
      clearTimeout(this.timeout);
    }

    this.dialog.classList.remove('visible');
  }

  async search(query) {
    let results = await this.searchIndex(query);

    if(window.gtag) {
      window.gtag('event', 'search', { event_category: 'engagement', event_label: query });
    }

    if(results.length == 0) {
      this.showMessage('No results');
    } else {
      this.resetResults();

      results.forEach((result, index) => {
        let doc = this.documents[result.ref];;
        let fragment = this.buildSearchResult(result, doc);
        let element = fragment.firstElementChild;

        this.results.appendChild(fragment);
      });
    }
  }

  resetResults() {
    [...this.results.children].map((el) => el.remove());
  }

  showMessage(message) {
    this.resetResults();

    let element = document.createElement('li');
    element.textContent = message;

    this.results.appendChild(element);
  }

  buildSearchResult(result, doc) {
    let element = this.template.content.cloneNode(true);
    element.querySelector('a').href = doc.path;
    element.querySelector('[data-field=title]').textContent = doc.title;
    element.querySelector('[data-field=description]').textContent = doc.description;

    Object.keys(result.matchData.metadata).forEach(function (term) {
      Object.keys(result.matchData.metadata[term]).forEach(function (fieldName) {
        var field = element.querySelector('[data-field=' + fieldName + ']'),
            positions = result.matchData.metadata[term][fieldName].position

        if(field) {
          mark(field, positions)
        }
      })
    });

    return element;
  }

  get currentSelection() {
    return this.results.querySelector('a');
  }

  async loadIndex() {
    if(!this.index) {
      let response = await fetch(this.path);
      let data = await response.json();

      this.index = lunr.Index.load(data.index);
      this.documents = data.documents;
    }
  }

  async searchIndex(query) {
    if(isPresent(query)) {
      await this.loadIndex();
      return this.index.search(query)
    } else {
      return [];
    }
  }
}
