export default class Header {
  constructor(selector = 'body > header') {
    this.element = document.querySelector(selector);
    this.update();

    window.addEventListener('scroll', (event) => this.update(event))
  }

  update() {
    let scrollY = Math.min(Math.max(window.scrollY, 0), 1500);
    this.element.style.setProperty('--scroll-y', `${scrollY}px`);
  }
}
