import Cookies from 'js-cookie';

function extractDomain(domain) {
  let parts = domain.split('.');
  let index = Math.max(parts.length - 2, 0);

  parts = parts.slice(index);

  if(parts.length > 1) {
    parts.unshift('');
  }

  return parts.join('.');
}

export default Cookies.withAttributes({
  path: '/',
  expires: 90,
  domain: extractDomain(document.domain)
});
