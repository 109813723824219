export default class PricingTable {
  constructor(element) {
    this.element = element;

    this.update();

    this.rangeInput.addEventListener('input', () => this.update());
    window.addEventListener('hashchange', () => this.update());
  }

  update() {
    this.volumeOutput.innerText = this.units.toLocaleString('en');

    let localeOptions = {
      trailingZeroDisplay: 'stripIfInteger',
      style: 'currency',
      currency: 'USD'
    }

    for(let plan of this.plans) {
      let tiers = JSON.parse(plan.dataset[this.mode]);

      let element = plan.querySelector('.price span');
      element.innerText = this.calculatePrice(tiers).toLocaleString('en', localeOptions);

      let unitPriceElement = plan.querySelector('.unit-price');
      if(unitPriceElement) unitPriceElement.innerText = this.calculateUnitPrice(tiers).toLocaleString('en', localeOptions);

      let messagesElement = plan.querySelector('.messages');
      if(messagesElement) messagesElement.innerText = (Math.max(500000, this.units * 5)).toLocaleString('en');
    }

    for(let button of this.buttons) {
      if(button.hash === `#${this.mode}`) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    }
  }

  get unitSize() {
    return Number(this.rangeInput.step, 10);
  }

  get units() {
    let { unitSize } = this;

    let volume = Number(this.rangeInput.value, 10);
    let limit = Number(this.rangeInput.min, 10);
    let maximum = Number(this.rangeInput.max, 10);

    let minimumPosition = limit / unitSize;
    let maximumPosition = maximum / unitSize;
    let position = volume / unitSize;

    let minimumValue = Math.log(limit);
    let maximumValue = Math.log(maximum);

    var scale = (maximumValue - minimumValue) / (maximumPosition - minimumPosition);

    volume = Math.exp(minimumValue + scale * (position - minimumPosition));
    volume = Math.round(volume / unitSize) * unitSize;

    return volume;
  }

  calculatePrice(tiers, value = this.units) {
    if(typeof tiers != 'object') return tiers;

    let total = 0;
    let count = 0;

    for(let { quota, unit_price, flat_price } of tiers) {
      quota ??= Infinity;

      let amount = Math.min(quota - count, value);
      value = Math.max(0, value - amount);
      total += flat_price + (Math.ceil(amount / this.unitSize) * unit_price);
      count += quota;
    }

    return total;
  }

  calculateUnitPrice(tiers, value = this.units) {
    if(typeof tiers != 'object') return tiers;

    let nextTier = tiers.find(({ quota }) => (quota ?? Infinity) > value) ?? {};

    return nextTier.unit_price;
  }

  get mode() {
    return (window.location.hash === '#annual') ? 'annual' : 'monthly';
  }

  get plans() {
    return this.element.querySelectorAll('.plan[data-annual]')
  }

  get buttons() {
    return this.element.querySelectorAll('.pricing-switcher a');
  }

  get volumeOutput() {
    return this.element.querySelector('[data-volume]');
  }

  get rangeInput() {
    return this.element.querySelector('input[type=range]');
  }
}
