import Menu from './menu';

export default class HoverMenu extends Menu {
  constructor() {
    super(...arguments);

    this.timeouts = {};
  }

  setupEventListeners() {
    for(let trigger of this.triggers) {
      trigger.addEventListener('mouseenter', (event) => this.triggerEntered(trigger, event));
      trigger.addEventListener('mouseleave', (event) => this.triggerLeft(trigger, event));
    }

    for(let menu of this.menus) {
      menu.addEventListener('mouseenter', (event) => this.menuEntered(menu, event));
      menu.addEventListener('mouseleave', (event) => this.menuLeft(menu, event));
    }
  }

  triggerEntered(trigger) {
    let menu = this.menuForTrigger(trigger);
    this.openMenu(menu);
  }

  triggerLeft(trigger) {
    let menu = this.menuForTrigger(trigger);
    this.closeMenu(menu);
  }

  menuEntered(menu) {
    this.openMenu(menu);
  }

  menuLeft(menu) {
    this.closeMenu(menu);
  }

  openMenu(menu) {
    if(this.timeouts[menu.id]) {
      clearTimeout(this.timeouts[menu.id]);
    }

    this.positionArrow(menu);
    super.openMenu(menu);
  }

  positionArrow(menu) {
    let trigger = this.triggerForMenu(menu);

    let triggerCenter =  trigger.offsetLeft + (trigger.clientWidth / 2);
    let viewportCenter = document.body.clientWidth / 2;

    let offset = triggerCenter - viewportCenter;

    this.container.style.setProperty('--arrow-offset', offset + 'px');
  }

  closeMenu(menu) {
    if(this.timeouts[menu.id]) {
      clearTimeout(this.timeouts[menu.id]);
    }

    this.timeouts[menu.id] = setTimeout(() => super.closeMenu(menu), 100);
  }
}
