export default class Menu {
  constructor(container, selector = '[aria-haspopup]', options = {}) {
    this.container = document.querySelector(container);
    this.selector = selector;
    this.options = options;

    if(this.container) {
      this.setupEventListeners();
    }
  }

  setupEventListeners() {
    for(let trigger of this.triggers) {
      trigger.addEventListener('click', (event) => this.triggerClicked(trigger, event));
    }
  }

  triggerClicked(trigger, event) {
    event.preventDefault();

    let menu = this.menuForTrigger(trigger);

    this.toggleMenu(menu);
  }

  toggleMenu(element) {
    if(this.isOpen(element)) {
      this.closeMenu(element);
    } else {
      this.openMenu(element);
    }
  }

  openMenu(element) {
    let trigger = this.triggerForMenu(element);

    if(this.options.exclusive) {
      this.menus.forEach((el) => this.closeMenu(el));
    }

    element.classList.add('visible');
    trigger.setAttribute('aria-expanded', true);

    this.container.dataset.expanded = this.hasOpenMenu;
  }

  closeMenu(element) {
    let trigger = this.triggerForMenu(element);

    element.classList.remove('visible');
    trigger.setAttribute('aria-expanded', false);

    this.container.dataset.expanded = this.hasOpenMenu;
  }

  isOpen(element) {
    return element.classList.contains('visible');
  }

  triggerForMenu({ id }) {
    return this.triggers.find((trigger) => trigger.getAttribute('aria-controls') == id);
  }

  menuForTrigger(trigger) {
    let id = trigger.getAttribute('aria-controls');
    return this.container.querySelector(`#${id}`);
  }

  get hasOpenMenu() {
    return this.triggers.some((el) => el.getAttribute('aria-expanded') == 'true');
  }

  get triggers() {
    return [...this.container.querySelectorAll(this.selector)];
  }

  get menus() {
    return this.triggers.map((trigger) => this.menuForTrigger(trigger));
  }
}
