import 'intersection-observer';

export default class Transitions {
  constructor() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
    }

    let elements = document.querySelectorAll('.viewport-transition')
    let observer = new IntersectionObserver(this.callback, options);

    for(let element of elements) {
      observer.observe(element);
    }
  }

  callback(entries) {
    for(let entry of entries) {
      let height = entry.target.getBoundingClientRect().height;
      let ratio = entry.intersectionRatio;

      if(ratio >= 0.2 || height * ratio > 25) {
        entry.target.classList.add('viewport-entered');
        entry.target.classList.add('viewport-inside');
        entry.target.classList.remove('viewport-outside');
      } else {
        entry.target.classList.add('viewport-inside');
        entry.target.classList.remove('viewport-outside');
      }
    }
  }
}
